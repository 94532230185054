import React, { useState, useEffect } from "react";
import CustomTable from "../components/CustomTable";
import Header from "../components/Header";
import DropdownAction from "../components/DropdownAction";

import { useSearchParams } from "react-router-dom";

import useWebSocket from "react-use-websocket";
import toast from "react-hot-toast";
import axios from "axios";

const formatGetMinerData = (getMinerRes) => {
  let selfCpu;
  let selfNet;
  let wax;
  if (!getMinerRes.self_delegated_bandwidth) {
    selfCpu = 0;
  } else {
    selfCpu = parseFloat(
      getMinerRes.self_delegated_bandwidth.cpu_weight
    ).toFixed(2);
  }

  if (!getMinerRes.self_delegated_bandwidth) {
    selfNet = 0;
  } else {
    selfNet = parseFloat(
      getMinerRes.self_delegated_bandwidth.net_weight
    ).toFixed(2);
  }

  if (!getMinerRes.wax_balance) {
    wax = 0;
  } else {
    wax = parseFloat(getMinerRes.wax_balance).toFixed(2);
  }

  if (!getMinerRes.land) {
    getMinerRes.land = {
      data: {
        name: "not register",
        commission: 10000,
      },
    };
  }

  if (!getMinerRes.last_mine) {
    getMinerRes.last_mine = "2020-01-01T00:00:00";
  }

  if (!getMinerRes.inventory) {
    getMinerRes.inventory = [];
  }

  let lastminetlm = "?.???? TLM";
  if (getMinerRes.lasttlm) {
    lastminetlm = getMinerRes.lasttlm;
    localStorage.setItem(`${getMinerRes.miner}`, `${getMinerRes.lasttlm}`);
  } else {

    const getCacheTLM = localStorage.getItem(`${getMinerRes.miner}`);
    if (getCacheTLM) {
      if (getCacheTLM.includes(":")) {
        localStorage.removeItem(getMinerRes.miner);
        getLastMine(getMinerRes.last_mine_tx).then(v => {
          if (v !== "?.???? TLM") {
            localStorage.setItem(`${getMinerRes.miner}`, v);
            lastminetlm = v;
          }
        })
      } else {
        lastminetlm = getCacheTLM;
      }
    } else {
      if (getMinerRes.last_mine_tx) {
        getLastMine(getMinerRes.last_mine_tx).then(v => {
          if (v !== "?.???? TLM") {
            localStorage.setItem(`${getMinerRes.miner}`, v);
            lastminetlm = v;
          }
        })
      }
    }
  }

  let latesec = 0;
  let waitsec = 0;
  if (getMinerRes.bags.length > 0) {
    const now = Date.now();
    const miner_last_mine_ms = Date.parse(getMinerRes.last_mine + ".000Z");
    const tools_last_mine_ms = getMinerRes.bags_use.reduce((max, item) => (item.last_use > max ? item.last_use : max), -Infinity) * 1000;

    let delay_miner = parseInt((miner_last_mine_ms + getMinerRes.params.delay * 1000 - now) / 1000);
    let delay_tools = parseInt((tools_last_mine_ms + getMinerRes.params.delay * 1000 - now) / 1000);

    if (delay_miner > 0) {
      waitsec = delay_miner;
    } else if (delay_tools > 0) {
      waitsec = delay_tools;
    } else {
      latesec = parseInt(now - (tools_last_mine_ms + getMinerRes.params.delay * 1000)) / 1000;
    }

    
  }

  return {
    NO:
      getMinerRes.id !== "MAINSTAKE"
        ? parseInt(getMinerRes.id)
        : getMinerRes.id,
    Account: getMinerRes.miner,
    CPU: (getMinerRes.cpu_limit.current_used * 100) / getMinerRes.cpu_limit.max,
    NET: (getMinerRes.net_limit.current_used * 100) / getMinerRes.net_limit.max,
    RAM:
      (getMinerRes.total_resources.ram_usage * 100) /
      getMinerRes.total_resources.ram_quota,
    SCPU: [
      selfCpu,
      (parseFloat(getMinerRes.total_resources.cpu_weight) - selfCpu).toFixed(2),
    ],
    SNET: [
      selfNet,
      (parseFloat(getMinerRes.total_resources.net_weight) - selfNet).toFixed(2),
    ],
    BAL: [
      parseFloat(getMinerRes.token_tlm).toFixed(2),
      parseFloat(getMinerRes.claim_tlm).toFixed(2),
      getMinerRes.claim_tlm_time + ".000Z",
    ],
    WAX: [wax, getMinerRes.refund],
    Land: `${getMinerRes.land.data.name}(${parseFloat(
      getMinerRes.land.data.commission / 100
    ).toFixed(1)}%)`, // Fixed Land name
    Bags: getMinerRes.bags.map((item) => {
      return { img_code: item.data.img, amount: 1 };
    }),
    Pts: [
      getMinerRes.points > 0 ? getMinerRes.points / 10 : getMinerRes.points,
      getMinerRes.total_points > 0
        ? getMinerRes.total_points / 10
        : getMinerRes.total_points / 10,
    ],
    LastMine: getMinerRes.last_mine,
    Delay: waitsec < 0 ? "" : waitsec,
    Wait: latesec < 0 ? "" : latesec,
    Status: getMinerRes.status,
    Inventory: getMinerRes.inventory.map((nft) => {
      return {
        template_id: nft.template_id,
        img_code: nft.item,
        amount: nft.amount,
      };
    }),
    flagged: getMinerRes.flagged,
    lastminetlm: lastminetlm,
  };
};

const historyEndpoints = [
  "https://wax.blokcrafters.io",
  "https://wax-hyperion.alcor.exchange",
  "https://history-wax-mainnet.wecan.dev",
  "https://wax.cryptolions.io",
  "https://wax.dapplica.io",
  "https://wax-history.eosdac.io",
  "https://wax.eosdublin.io",
  "https://hyperion.wax.detroitledger.tech",
  "https://wax.eosphere.io",
  "https://api.wax.greeneosio.com",
  "https://api.wax.liquidstudios.io",
  "https://wax.hivebp.io",
  "https://api.waxsweden.org",
];

let apiuse = 0;

const getHistoryEndpoint = () => {
  if (apiuse > historyEndpoints.length - 1) {
    apiuse = 0;
  }
  const returnApi = historyEndpoints[apiuse];
  apiuse++;
  return returnApi;
};

const getTransaction = (api, tx) =>
  axios
    .post(`${api}/v1/history/get_transaction`, {
      id: tx,
    })
    .catch((e) => { });

const getLastMine = async (txid) => {
  try {
    const gettx = await getTransaction(
      getHistoryEndpoint(),
      txid
    );
    const findNotify = gettx.data.traces.filter(
      (action) => action.receipt.receiver === "notify.world"
    );
    if (findNotify.length > 0) {
      const bounty = findNotify[0].act.data.bounty;
      return bounty;
    }
  } catch (error) {
    return "?.???? TLM";
  }
};

const Monitor = () => {
  const [searchParams] = useSearchParams();
  const GoogleId = searchParams.get("googleid");
  const PortName = searchParams.get("port");
  document.title = PortName;

  const [portData, setPortData] = useState(false);
  const [queryData, setQueryData] = useState([]);
  const [headerData, setHeaderData] = useState({
    totalTLM: 0,
    totalClaim: 0,
    overAllTLM: 0,
    totalWax: 0,
    refundingWax: 0,
    overAllWax: 0,
    stakeAll: 0,
    botAlive: 0,
    botFlag: 0,
    timeRangeText: "00.00-00.00",
    portName: "",
    autoClaim: "",
    claimAt: 0,
    mainStake: "",
    transferTo: "",
    version: "0.0.0",
  });

  useEffect(() => {
    if (portData) {
      // console.log(portData, GoogleId, PortName)
      // getLastMine();
      // console.log();
      if (portData["ports"]) {
        setQueryData(Array.from(portData["ports"]));
      }
      if (portData["statics"]) {
        let stats = portData["statics"];
        let totalStake = 0;
        let totalwax = 0;
        for (const miner of Array.from(portData["ports"])) {
          if (miner.id !== "MAINSTAKE") {
            const miner_stake = miner.self_delegated_bandwidth;
            if (miner_stake) {
              totalStake += parseFloat(miner_stake.cpu_weight);
            }
            if (miner.wax_balance) {
              totalwax += parseFloat(miner.wax_balance);
            }
          }
        }

        totalStake = parseFloat(totalStake).toFixed(2);
        totalwax = parseFloat(totalwax).toFixed(2);

        const totalbot = Array.from(portData["ports"]).filter(
          (b) => b.id !== "MAINSTAKE"
        ).length;
        const totalflag = Array.from(portData["ports"]).filter(
          (b) => b.flagged
        ).length;

        if (portData["config"]) {
          stats.transferTo = portData["config"].transferTo;
          stats.mainstake = portData["config"].mainstake;
          stats.version = portData["config"].version;
          stats.claim = portData["config"].claim;
          stats.cycle = portData["config"].cycle;
        }

        setHeaderData({
          totalTLM: parseFloat(stats.t_tlm),
          totalClaim: parseFloat(stats.c_tlm),
          overAllTLM: parseFloat(
            parseFloat(stats.c_tlm) + parseFloat(stats.t_tlm)
          ).toFixed(2),
          totalWax: stats.b_wax,
          refundingWax: 0,
          overAllWax: totalwax,
          stakeAll: totalStake,
          botAlive: totalbot,
          botFlag: totalflag,
          timeRangeText: stats.cycle,
          portName: PortName,
          autoClaim: "on",
          claimAt: stats.claim,
          mainStake: stats.mainstake ? "on" : "off",
          transferTo: stats.transferTo,
          version: stats.version ? stats.version : "not found",
        });
      }
    }
  }, [portData]);

  const [num, setNum] = useState(0);

  // useEffect(
  //   () => {
  //     if (GoogleId && PortName) {
  //       axios.get(`https://anonymous-aw.com/api/monitor`, {
  //         headers: {
  //           'x-user-id': GoogleId
  //         }
  //       }).then((res) => {
  //         setPortData(res.data);
  //       }).catch((err) => console.log(err));
  //     }
  //   },
  //   []
  // );

  useEffect(() => {
    const intervalId = setInterval(async () => {
      setNum(num + 1);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [num]);

  const {
    sendMessage,
    sendJsonMessage,
    lastMessage,
    lastJsonMessage,
    readyState,
    getWebSocket,
  } = useWebSocket(
    `wss://anonymous-server.meta-dev.workers.dev/monitor?googleid=${GoogleId}&port=${PortName}`,
    {
      onOpen: () => {
        console.log("open connection to server");
        sendMessage(
          JSON.stringify({
            type: "monitorData",
            message: "",
          })
        );
        setInterval(() => {
          sendMessage(
            JSON.stringify({
              type: "monitorData",
              message: "",
            })
          );
        }, 10000);
      },
      onMessage: (message) => {
        try {
          const { type, message: bot_message } = JSON.parse(message.data);
          switch (type) {
            case "message": {
              const { status, message: _message } = bot_message;
              if (status === "success") {
                toast.success(_message, { duration: 5000 });
              } else if (status === "loading") {
                toast.loading(_message, { duration: 3000 });
              } else if (status === "error") {
                toast.error(_message, { duration: 10000 });
              }
              break;
            }
            case "monitorData": {
              console.log(bot_message);
              if (bot_message["statics"] && bot_message["ports"]) {
                setPortData(bot_message);
              } else {
                setQueryData([]);
              }
              break;
            }
            default:
              break;
          }
        } catch (error) {
          console.log(error);
        }
      },
      shouldReconnect: (closeEvent) => true,
    }
  );

  const data = queryData.map((data) => formatGetMinerData(data));
  // const results = resultData(queryData);

  const [selectedIds, setSelectedIds] = useState([]);

  const handleSelectChange = (ids) => setSelectedIds(ids);

  return (
    <div className="monitor">
      <Header headerData={headerData} />
      <CustomTable data={data} onSelect={handleSelectChange}></CustomTable>
      <div className="dropdown-container">
        <DropdownAction
          port={PortName}
          idChoose={selectedIds}
          transferTo={headerData.transferTo}
          serverSendMessage={sendMessage}
        />
      </div>
    </div>
  );
};

export default Monitor;
