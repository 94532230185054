
function Header({headerData}) {
  return (
    <div className="for-background">
        <div className="left-header-container">
            <div className="left-header">
                <div className="coin-container">
                    <img src='/tlmlogo.png' style={{width:'0.8rem' ,height: '0.8rem'}}></img>
                    <p>{headerData.totalTLM.toFixed(2)} [{headerData.totalClaim.toFixed(2)}] ➡️ {headerData.overAllTLM}</p>
                </div>
                
                <div className="coin-container">
                    <img src='/waxlogo.png' style={{width:'0.8rem' ,height: '0.8rem'}}></img>
                    <p>{headerData.totalWax} [{headerData.refundingWax}] ➡️ {headerData.overAllWax} | SCPU: {headerData.stakeAll}</p>
                </div>
                <div className="coin-container">
                    <p>Bot: {headerData.botAlive} - Flag: {headerData.botFlag}</p>
                </div>
            </div>

            
            <h3>Anonymous Monitor 2025</h3>
            <div className="left-header">
                <p>Port: {headerData.portName} ({headerData.timeRangeText})</p>
                <p>AutoClaim: {headerData.autoClaim} @{headerData.claimAt}</p>
                <p>Mainstake: {headerData.mainStake}</p>
                <p>Version: {headerData.version}</p>
            </div>
        </div>
    </div>

  );
}

export default Header;